input,
textarea,
select {
  background: $white;
  border: 0;
  border-radius: 0;
  color: $black;
  display: block;
  font: inherit;
  line-height: inherit;
  margin: 0;
  padding: .5em .75em;
  width: 100%; }

