.sidebar {
  background: $white;
  height: 100%;
  position: fixed;
  top: 100%;
  transition: transform .5s 0s ease;
  width: 100%;
  z-index: 10;

  @media (min-width: $smallDesktop) {
    height: 100vh;
    z-index: 8; }

  .sidebar-open & {
    transform: translateY(-100%);
    transition: transform .5s .375s ease;

    .audio-play__mobile-cta,
    .audio-player__now-playing {
      opacity: 0;
      pointer-events: none;
      transform: translateY(100%);
      transition: opacity 0s .375s ease, transform .375s 0s ease; } }

  @media (min-width: $smallDesktop) {
    flex: 0 0 30%;
    position: sticky;
    top: 0;
    transform: none !important; }

  &__container {
    flex-direction: column;
    height: 100%;
    justify-content: space-between;
    margin: 0 auto;
    max-width: 75vw;
    overflow-y: auto;
    padding-bottom: ($baseline * 2);
    padding-top: ($baseline * 3);

    @media (min-width: $smallDesktop) {
      padding-top: ($baseline * 5); }

    @media (min-width: $largeDesktop) {
      padding-top: ($baseline * 6); } }

  .main-nav {
    width: 100%;

    &__list {
      list-style: none; }

    &__item {
      font-weight: 700;
      line-height: ($baseline * 1.5);
      letter-spacing: -.025em;
      margin: 0;
      text-align: center;

      @media (min-width: $smallDesktop) {
        line-height: $baseline;
        text-align: left; }

      @media (min-width: $largeDesktop) {
        line-height: 2em; }

      &--large {
        font-size: 2.25em;
        font-weight: 900;
        line-height: #{(1.35 / 1.5) * $baseline};

        @media (min-width: $smallDesktop) {
          font-size: 1.5em; }

        @media (min-width: $largeDesktop) {
          font-size: 2.25em; } } }

    &__link {
      text-decoration: none; } }

  &__close {
    align-items: center;
    display: inline-flex;
    font-size: .875em;
    line-height: 1em;
    padding: $baseline;
    position: absolute;
    right: 0;
    text-decoration: none;
    top: 0;
    top: constant(safe-area-inset-top);
    top: env(safe-area-inset-top);
    transition: opacity .5s ease;

    &:hover {
      opacity: .5; }

    &::after {
      background: url(/img/icons/x.svg) center / contain no-repeat;
      content: '';
      display: inline-block;
      height: ($baseline / 1.5);
      margin-left: ($baseline / 4);
      width: ($baseline / 1.5); }

    @media (min-width: $smallDesktop) {
      display: none !important; } } }
