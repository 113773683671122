.footer {
  background: darken($black, 7%);
  color: $white;
  padding: ($baseline * 3) $baseline ($baseline * 4);

  @media (min-width: $largeMobile) {
    padding-bottom: ($baseline * 6); }

  @media (min-width: $smallDesktop) {
    padding-bottom: ($baseline * 2); }

  &__social {
    margin: 0 0 ($baseline * 2); }

  &__attribution {
    color: rgba($gray, .3);
    font-size: .75em;
    text-align: center;

    > span,
    > a {
      &:not(:last-child) {
        &::after {
          color: rgba($gray, .3);
          content: '|';
          margin: 0 1ch; } } }

    a {
      text-decoration: none;
      transition: color .5s ease;

      &:hover {
        color: $white; } } } }
