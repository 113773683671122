@function between($from, $to, $fromWidth, $toWidth) {
  $slope: ($from - $to) / ($fromWidth - $toWidth);
  $base: $to - $slope * $toWidth;

  @return calc(#{$base} + #{100vw * $slope}); }

@mixin clamp($property, $from, $to, $fromWidth, $toWidth) {
  #{$property}: $from;

  @media (min-width: $fromWidth) {
    #{$property}: between($from, $to, $fromWidth, $toWidth); }

  @media (min-width: $toWidth) {
    #{$property}: $to; } }

@mixin cover($padding: 0) {
  bottom: $padding;
  left: $padding;
  position: absolute;
  right: $padding;
  top: $padding; }
