.page--subscribe {
  .content {
    background: $orange;
    color: $white; }

  .instagram {
    &__title {
      h3 {
        color: $white; } } } }

.subscribe {
  padding: 12.5vmax 0 ($baseline * 3);
  text-align: center;

  &__title {
    color: $white;
    font-size: 5vmax;
    // letter-spacing: .25em
    margin: 0 0 3rem;
    padding: 3rem 0;
    position: relative;
    z-index: 3;

    &::before {
      @include cover;
      background: url(/img/icons/logo.svg) center / contain no-repeat;
      content: '';
      opacity: .1;
      z-index: -1; } }

  &__text {
    margin: 0 auto ($baseline * 2);
    max-width: 24em; }

  &__form {
    align-items: stretch;
    display: flex;
    flex-wrap: wrap;
    margin: 0 auto $baseline;
    max-width: 24em;

    input {
      flex: 1 1; }

    button {
      background: $black;
      border-color: $black;
      color: $white;
      flex: 0 0 auto;

      &:hover {
        background: $black;
        border-color: $black;
        color: $orange; } } }

  &__error {
    background: $red;
    color: $white;
    font-size: .875em;
    flex: 0 0 100%;
    padding: .25em .5em; } }
