@keyframes rotate {
  0% {
    transform: rotate(0deg); }

  100% {
    transform: rotate(360deg); } }

.audio-player {
  margin: 0 auto;
  max-width: 50vh;
  width: 100%;

  &__title {
    font-size: .875em;
    margin: 0 0 1em;
    transition: opacity .5s ease; }

  &__mobile-cta,
  &__now-playing {
    align-items: center;
    background: $white;
    bottom: 100%;
    color: $black;
    display: flex;
    left: 0;
    padding-bottom: 0;
    padding-bottom: constant(safe-area-inset-bottom);
    padding-bottom: env(safe-area-inset-bottom);
    position: absolute;
    text-decoration: none;
    transition: color .5s ease, opacity 0s .5s ease, transform .375s .5s ease;
    width: 100vw;

    &:hover {
      color: $orange; }

    @media (min-width: $smallDesktop) {
      display: none; }

    &-artwork {
      flex: 0 0 ($baseline * 2);
      margin: 0 ($baseline / 2) 0 0;

      @media (min-width: $largeMobile) {
        flex-basis: ($baseline * 3); } }

    &-text {
      margin: 0 auto; }

    &-subtext {
      display: none;

      @media (min-width: $smallMobile) {
        display: inline; } } }

  &__now-playing {
    display: none; }

  &__cta {
    background: $gray;
    margin: 0;
    position: relative;
    width: 100%;

    &::before {
      animation: rotate 1s linear infinite running;
      background: url(/img/icons/loading.svg) center / contain no-repeat;
      content: '';
      height: $baseline;
      left: 50%;
      margin-left: ($baseline / -2);
      margin-top: ($baseline / -2);
      position: absolute;
      top: 50%;
      width: $baseline;
      z-index: 1; }

    &-link {
      display: block;
      position: relative;

      &::after {
        @include cover;
        background: rgba($black, .9) url(/img/icons/play-white.svg) center / ($baseline * 2) no-repeat;
        content: '';
        mix-blend-mode: hard-light;
        opacity: 0;
        pointer-events: none;
        transition: opacity .5s ease;
        z-index: 3; }

      &:hover {
        &::after {
          opacity: 1; } } }

    &-artwork {
      margin: 0 auto;
      position: relative;
      width: 100%;
      z-index: 2;

      picture,
      img {
        width: 100%;
        z-index: 2; } } }

  &__waveform {
    margin: $baseline 0 0;
    max-height: 0;
    transition: margin 1s ease, max-height 1s ease; }

  &__controls {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    font-size: .75em;
    justify-content: center;
    max-height: 0;
    overflow: hidden;
    transition: max-height 1s ease;

    @media (min-width: $smallDesktop) {
      flex-wrap: nowrap;
      justify-content: flex-start; } }

  &__meta {
    flex: 0 0 100%;
    margin: ($baseline / 4) 0;
    order: -1;
    text-align: center;

    @media (min-width: $smallDesktop) {
      flex: 1 1;
      line-height: 1.25em;
      order: initial;
      text-align: left; }

    .audio-player {
      &__track-title {
        display: block;
        font-size: $baseline;

        @media (min-width: $smallDesktop) {
          font-size: 1em; } }

      &__release-title {
        color: rgba($black, .5);

        @media (min-width: $smallDesktop) {
          font-size: .875em; } } } }

  &__button {
    appearance: none;
    border: 0;
    cursor: pointer;
    display: inline-block;
    font: inherit;
    height: ($baseline * 2);
    margin: 0 ($baseline / 4) 0 0;
    width: ($baseline * 2);

    &:last-of-type {
      margin-right: 0; }

    @media (min-width: $smallDesktop) {
      height: $baseline;
      width: $baseline;

      &:last-of-type {
        margin-right: $baseline; } }

    &--previous {
      background: url(/img/icons/previous.svg) center / contain no-repeat;

      &:hover {
        background: url(/img/icons/previous-orange.svg) center / contain no-repeat; } }

    &--play,
    &--pause {
      height: ($baseline * 2.5);
      width: ($baseline * 2.5);

      @media (min-width: $smallDesktop) {
        height: ($baseline * 1.25);
        width: ($baseline * 1.25); } }

    &--play {
      background: url(/img/icons/play.svg) center / contain no-repeat;
      transform: translateX(2px);

      &:hover {
        background: url(/img/icons/play-orange.svg) center / contain no-repeat;
        transform: translateX(1px); } }

    &--pause {
      background: url(/img/icons/pause.svg) center / contain no-repeat;
      display: none;

      &:hover {
        background: url(/img/icons/pause-orange.svg) center / contain no-repeat; } }

    &--next {
      background: url(/img/icons/next.svg) center / contain no-repeat;

      &:hover {
        background: url(/img/icons/next-orange.svg) center / contain no-repeat; } } }

  &--playing {
    .audio-player {
      &__button {
        &--play {
          display: none; }

        &--pause {
          display: inline-block; } } } }

  &--launched {
    .audio-player {
      &__title {
        height: 0;
        margin: 0;
        opacity: 0;
        pointer-events: none;
        transition: height .5s ease, margin .5s ease; }

      &__waveform {
        margin: $baseline 0 0;
        max-height: ($baseline * 3); }

      &__controls {
        max-height: ($baseline * 6);

        @media (min-width: $smallDesktop) {
          max-height: ($baseline * 2); } }

      &__cta {
        &-link {
          cursor: default;
          pointer-events: none;

          &::after {
            content: none; } } }

      &__mobile-cta {
        display: none; }

      &__now-playing {
        display: flex; } } }

  &--loading {
    .audio-player {
      &__button {
        &--play,
        &--pause {
          animation: rotate 1s linear infinite running;
          background-image: url(/img/icons/loading.svg);
          pointer-events: none; } } } } }
