.music {
  &__releases {
    align-items: flex-start;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 0 0 ($baseline * 2); }

  &__section-title {
    flex: 0 0 100%; }

  &__thumbnail.release-thumbnail {
    @media (min-width: $tablet) and (max-width: ($largeDesktop - 1)) {
      margin-right: $baseline;
      width: calc(50% - #{$baseline});

      // First row
      &:nth-of-type(1),
      &:nth-of-type(1) ~ dt:nth-of-type(-n+2) {
        order: 1; }

      // Second row
      &:nth-of-type(3),
      &:nth-of-type(3) ~ dt:nth-of-type(-n+4) {
        order: 3; }

      // Third row
      &:nth-of-type(5),
      &:nth-of-type(5) ~ dt:nth-of-type(-n+6) {
        order: 5; }

      // Fourth row
      &:nth-of-type(7),
      &:nth-of-type(7) ~ dt:nth-of-type(-n+8) {
        order: 7; }

      &:nth-of-type(2n) {
        margin-right: 0; }

      // Ensure items are always wrapped left
      &:nth-of-type(2n+1):nth-last-of-type(-n+2):last-of-type {
        margin-right: 50%; } }

    @media (min-width: $largeDesktop) {
      width: calc(33.3333% - ((4/3) * #{$baseline}));

      // First row
      &:nth-of-type(1),
      &:nth-of-type(1) ~ dt:nth-of-type(-n+3) {
        order: 1; }

      // Second row
      &:nth-of-type(4),
      &:nth-of-type(4) ~ dt:nth-of-type(-n+6) {
        order: 3; }

      // Third row
      &:nth-of-type(7),
      &:nth-of-type(7) ~ dt:nth-of-type(-n+9) {
        order: 5; }

      // Fourth row
      &:nth-of-type(10),
      &:nth-of-type(10) ~ dt:nth-of-type(-n+12) {
        order: 7; }

      &:nth-of-type(1n) {
        margin-right: $baseline; }

      &:nth-of-type(3n) {
        margin-right: 0; }

      // Ensure items are always wrapped left
      &:nth-of-type(3n+1):nth-last-of-type(-n+3) ~ dt:nth-of-type(3n+2):last-of-type {
        margin-right: 33.33%; }

      &:nth-of-type(3n+1):nth-last-of-type(-n+3):last-of-type {
        margin-right: 66.66%; } }

    &[aria-expanded="true"] + .music__release {
      max-height: 125vh; } }

  &__release {
    flex: 0 0 auto;
    left: 50%;
    max-height: 0;
    overflow: hidden;
    position: relative;
    transform: translateX(-50%);
    transition: max-height .5s ease;
    width: 100vw;

    @media (min-width: $smallDesktop) {
      width: 70vw; }

    &-wrapper {
      background: $grayThree;
      box-shadow: 0px 0px 20px rgba(0, 0, 0, .5);
      margin: 0;
      padding: ($baseline * 2) 0;
      width: 100%; }

    &-container {
      align-items: center;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;

      @media (min-width: $tablet) {
        flex-wrap: nowrap; } }

    @media (min-width: $tablet) and (max-width: ($largeDesktop - 1)) {
      // First row
      &:nth-of-type(1),
      &:nth-of-type(1) ~ dd:nth-of-type(-n+2) {
        order: 2; }

      // Second row
      &:nth-of-type(3),
      &:nth-of-type(3) ~ dd:nth-of-type(-n+4) {
        order: 4; }

      // Third row
      &:nth-of-type(5),
      &:nth-of-type(5) ~ dd:nth-of-type(-n+6) {
        order: 6; }

      // Fourth row
      &:nth-of-type(7),
      &:nth-of-type(7) ~ dd:nth-of-type(-n+8) {
        order: 8; } }

    @media (min-width: $largeDesktop) {
      // First row
      &:nth-of-type(1),
      &:nth-of-type(1) ~ dd:nth-of-type(-n+3) {
        order: 2; }

      // Second row
      &:nth-of-type(4),
      &:nth-of-type(4) ~ dd:nth-of-type(-n+6) {
        order: 4; }

      // Third row
      &:nth-of-type(7),
      &:nth-of-type(7) ~ dd:nth-of-type(-n+9) {
        order: 6; }

      // Fourth row
      &:nth-of-type(10),
      &:nth-of-type(10) ~ dd:nth-of-type(-n+12) {
        order: 8; } } } }

.release-thumbnail {
  cursor: pointer;
  flex: 0 0 auto;
  font-weight: normal;
  margin: $baseline 0;
  overflow: hidden;
  position: relative;
  width: 100%;

  &:hover,
  &[aria-expanded="true"] {
    .release-thumbnail__image--rear,
    .release-thumbnail__unreleased-mask {
      opacity: 1; } }

  img {
    height: auto;
    max-width: none;
    width: 100%; }

  &__image {
    &--rear {
      left: 50%;
      opacity: 0;
      position: absolute;
      top: 50%;
      transform: translate(-50%, -50%);
      transition: opacity .5s ease;
      width: calc(100% + 2px); } }

  &__unreleased-mask {
    @include cover;
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    opacity: 0;
    text-decoration: none;
    transition: opacity .5s ease;

    &::before {
      @include cover;
      background: rgba($black, .9);
      content: '';
      display: block;
      mix-blend-mode: hard-light;
      z-index: 0; } }

  &__release-date {
    @extend %h6;
    color: $white;
    z-index: 1; }

  .button {
    z-index: 1; } }

.release {
  align-items: stretch;
  display: flex;
  flex-wrap: wrap;

  @media (min-width: $smallTablet) {
    flex-wrap: nowrap; }

  &__artwork {
    flex: 0 0 100%;
    margin: 0 0 $baseline;
    position: relative;

    @media (min-width: $smallTablet) {
      flex: 0 0 50%;
      margin: 0 $baseline 0 0; }

    @media (min-width: $tablet) {
      margin: 0 ($baseline * 1.5) 0 0; }

    @media (min-width: $mediumDesktop) {
      margin: 0 ($baseline * 2) 0 0; }

    @media (min-width: $largeDesktop) {
      margin: 0 ($baseline * 3) 0 0; }

    &::after {
      @include cover;
      background: rgba($black, .9) url(/img/icons/play-white.svg) center / ($baseline * 2) no-repeat;
      content: '';
      mix-blend-mode: hard-light;
      opacity: 0;
      pointer-events: none;
      transition: opacity .5s ease;
      z-index: 3; }

    &:hover {
      &::after {
        opacity: 1; } } }

  &__details {
    align-items: flex-start;
    display: flex;
    flex: 0 0 100%;
    flex-direction: column;
    justify-content: center;
    margin: 0 auto;
    max-width: 18em;

    @media (min-width: $smallTablet) {
      flex: 1 1;
      max-width: auto; } }

  &__title {
    color: $white;
    padding-left: .5em; }

  &__playlist {
    color: $white;
    counter-reset: track-count;
    list-style: none;
    margin: 0 auto $baseline;
    width: 100%;

    @media (min-width: $smallTablet) {
      margin: 0 0 $baseline; } }

  &__track {
    align-items: baseline;
    counter-increment: track-count;
    display: flex;
    justify-content: flex-start;
    width: 100%;

    &-duration {
      display: inline-block;
      margin-right: 1ch; }

    &-link {
      @extend .button;
      @extend .button--listen;
      align-items: baseline;
      background: transparent;
      border: 0;
      display: flex;
      flex: 1 1;
      font-weight: 400;
      justify-content: flex-start;

      &:not(.playing) {

        &::before {
          background: none;
          color: $orange;
          content: '0' counter(track-count);
          font-weight: 900; }

        &:hover {
          align-items: center;

          &::before {
            background: url(/img/icons/play-white.svg) center / contain no-repeat;
            content: ''; } } } }

    &-duration {
      margin-left: auto; } }

  &__buttons {
    @media (max-width: $tablet) {
      margin: 0 auto;
      max-width: 18em; } } }
