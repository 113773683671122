html {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-smooth: always;
  background: $black; }

.page__wrapper {
  align-items: flex-start;
  display: flex; }

.content {
  background: $gray;
  color: $black;
  flex: 0 0 auto;
  width: 100%;

  @media (min-width: $smallDesktop) {
    width: 70%; }

  &__wrapper {
    transition: opacity .5s .5s ease;

    .loading & {
      opacity: 0;
      transition: opacity .5s ease; } } }

.container {
  margin: 0 auto;
  padding: 0 $baseline;

  @media (min-width: $tablet) {
    padding: 0 ($baseline * 1.5); }

  @media (min-width: $mediumDesktop) {
    padding: 0 ($baseline * 2); }

  @media (min-width: $largeDesktop) {
    padding: 0 ($baseline * 3); }

  &--flex {
    align-items: baseline;
    display: flex;
    justify-content: flex-start; } }

.turbolinks-progress-bar {
  display: none; }
