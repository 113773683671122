img,
picture,
picture source {
  display: block;
  transition: opacity 1s ease, transform 1s .25s ease;
  opacity: 1;
  min-height: 1px;

  source,
  img {
    width: 100%; } }

.image {
  overflow: hidden;

  img,
  picture source {
    &[data-lazy-load-src],
    &[data-lazy-load-srcset] {
      opacity: 0; } }

  &__image {
    width: 100%; } }

.objFit, .objFit picture {
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
  img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover; } }
