.intro {
  align-items: center;
  color: $white;
  display: flex;
  flex-direction: column;
  height: 100vh;
  justify-content: center;
  margin: 0 0 ($baseline * 2);
  overflow: hidden;
  position: relative;
  text-align: center;
  width: 100%;

  @media (min-width: $smallDesktop) {
    margin: 0 0 ($baseline * 4); }

  &__background {
    @include cover;
    z-index: 0;

    &--black {
      background: url(/img/releases/black.jpg) center / contain no-repeat, url(/img/bg-light.jpg) center / contain repeat; }

    &--rouge {
      background: url(/img/releases/rouge.jpg) center / contain no-repeat, url(/img/rouge-bg-dark.jpg) center / contain repeat; }

    &--blood {
      background: url(/img/releases/blood-bg.jpg) center / cover no-repeat;

      @media (min-width: 100vh) {
        background: url(/img/releases/blood.jpg) center / cover no-repeat; }

      @media (min-width: $smallDesktop) and (min-height: 70vw) {
        background: url(/img/releases/blood-bg.jpg) center / cover no-repeat; } }

    &--dust {
      background: url(/img/releases/dust.jpg) center / cover repeat-x; }

    &--azure {
      background: url(/img/releases/azure.jpg) center / contain repeat-x; }

    &--aurora {
      background: url(/img/releases/aurora.jpg) center / cover repeat-x; }

    &-logo {
      display: inline-block;
      left: 50%;
      overflow: hidden;
      position: absolute;
      top: 50%;
      transform: translate(-50%, -50%); }

    &-light {
      display: none;
      height: 100vh;
      width: 100vw; }

    &-dark {
      @include cover;
      // border-radius: 50%
      // height: 80vmin
 }      // width: 80vmin

    &-logo {
      display: none;
      width: 70vmin;

      .logo {
        &__group {
          mix-blend-mode: average;

          &--overlay {
            display: none; } } } }

    img,
    picture {
      height: 100vmax;
      object-fit: cover;
      object-position: center;
      width: 100vmax; } }

  &__content {
    padding-top: ($baseline * 2);
    transform: translateY(50%);
    z-index: 2;

    a {
      margin: ($baseline / 2) 0; } }

  @media (max-width: #{$largeMobile - 1}) {
    .listen {
      filter: invert(1);
      left: 50%;
      position: absolute;
      top: ($baseline * 8);
      transform: translateX(-50%);
      width: 100vw; } }

  &__title {
    font-size: 4vmin;
    letter-spacing: .75em;
    margin-top: 2em;
    text-align: center;
    transform: translateX(3.05%);

    &:last-letter {
      letter-spacing: normal; } }

  &__subtext {
    margin: $baseline 0; }

  .listen {
    margin-top: ($baseline / 2); } }
