.content-page {
  margin: 0 0 ($baseline * 2);

  &__container {
    margin: 0;
    max-width: 50em; } }

.page-subnav {
  display: flex;
  margin: 0 0 $baseline;

  &__link {
    border: solid transparent;
    border-width: 0 0 3px;
    font-weight: 700;
    margin: 0 $baseline 0 0;
    max-width: 100%;
    overflow-x: auto;
    text-decoration: none;
    transition: border-color .5s ease;

    &--active {
      border-color: $orange; } } }
