@keyframes playingIconBars {
  0% {
    height: 1.05em; }
  5% {
    height: 1.125em; }
  10% {
    height: .975em; }
  15% {
    height: 1.2em; }
  20% {
    height: .975em; }
  25% {
    height: 1.125em; }
  30% {
    height: 1.05em; }
  35% {
    height: 1.2em; }
  40% {
    height: .9em; }
  45% {
    height: 1.05em; }
  50% {
    height: .975em; }
  55% {
    height: 1.2em; }
  60% {
    height: 1.125em; }
  65% {
    height: 1.23em; }
  70% {
    height: .975em; }
  75% {
    height: 1.05em; }
  80% {
    height: .9em; }
  85% {
    height: 1.2em; }
  90% {
    height: 1.05em; }
  95% {
    height: 1.125em; }
  100% {
    height: 1.05em; } }

.button {
  align-items: center;
  border: 3px solid currentColor;
  color: $orange;
  cursor: pointer;
  display: inline-flex;
  font-size: .75em;
  font-weight: 700;
  letter-spacing: .075em;
  padding: .25em 1em;
  text-decoration: none;
  text-transform: uppercase;
  transition: background .5s ease, border-color .5s ease, color .5s ease, opacity .5s ease;

  &:hover {
    background: $orange;
    border-color: $orange;
    color: $white; }

  &--listen,
  &--presave {
    background: $orange;
    border-color: $orange;
    color: $white;

    &::before {
      background: url(/img/icons/headphones.svg) center / contain no-repeat;
      content: '';
      display: inline-block;
      height: $baseline;
      margin-right: ($baseline / 2);
      width: $baseline; }

    &.playing {
      align-items: baseline;
      padding-left: 3.25em;
      position: relative;

      &::before,
      &::after {
        animation: playingIconBars 3s linear infinite forwards;
        background: transparent;
        border: solid currentColor;
        border-width: 0 1px;
        bottom: .75em;
        content: '';
        display: inline-block;
        height: 0;
        order: -1;
        position: absolute;
        width: 3px; }

      &::before {
        left: 1.25em;
        margin-right: ($baseline / 8); }

      &::after {
        animation-delay: .05s;
        left: calc(1.25em + 8px);
        margin-right: ($baseline / 2); } }

    .page--subscribe & {
      background: $black;
      color: $orange; } }

  &--presave {
    &::before {
      background: url(/img/icons/spotify-white.svg) center / contain no-repeat; } }

  &--secondary {
    color: $white; } }
