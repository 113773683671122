@keyframes animateProgress {
  0% {
    transform: translateX(0); }

  49.9% {
    transform: translateX(100%); }

  50% {
    transform: translateX(-100%); }

  100% {
    transform: translateX(0); } }

@keyframes finishedLoading {
  0% {
    transform: translateX(-100%); }

  100% {
    transform: translateX(0); } }

.logo {
  overflow: hidden;

  .header & {
    rect {
      animation: animateProgress 1s linear forwards running;
      transform: translateX(0); }

    &--loading {
      rect {
        animation-iteration-count: infinite;
        animation-play-state: running; } } } }
