.instagram {
  &__title {
    align-items: center;
    display: inline-flex;
    margin: $baseline 0;
    padding: 0;
    text-decoration: none;
    transition: opacity .5s ease;
    width: auto;

    &:hover {
      opacity: .7; }

    h3 {
      color: $orange;
      display: block;
      font-weight: 900;
      margin: 0;
      white-space: nowrap; }

    img {
      flex: 0 0 $baseline;
      margin: 0 ($baseline / 2) 0 0; } }

  &__posts {
    align-items: stretch;
    display: flex;
    flex-wrap: wrap;
    margin: 0;
    justify-content: flex-end;

    @media (min-width: $tablet) {
      flex-wrap: nowrap; }

    &:hover {
      .instagram__post::after {
        opacity: 1; } } }

  &__post {
    display: block;
    flex: 0 0 50%;
    position: relative;

    @media (min-width: $tablet) {
      flex: 0 0 25%; }

    &::after {
      @include cover;
      background: rgba($black, .9);
      content: '';
      display: inline-block;
      mix-blend-mode: multiply;
      opacity: 0;
      pointer-events: none;
      transition: opacity .5s ease;
      z-index: 2; }

    &:hover {
      &::after {
        opacity: 0 !important; }

      .instagram__link::after {
        opacity: 1; } } }

  &__link {
    display: block;
    height: 100%;
    position: relative;
    width: 100%;
    z-index: 1;

    &::after {
      background: $orange url('/img/icons/instagram-white.svg') center / ($baseline * .75) no-repeat;
      border-radius: 50%;
      content: '';
      height: ($baseline * 1.5);
      left: 50%;
      opacity: 0;
      position: absolute;
      top: 50%;
      transition: opacity .5s ease;
      transform: translate(-50%, -50%);
      width: ($baseline * 1.5); }

    img {
      display: block;
      height: auto;
      width: 100%; } } }

