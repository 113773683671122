.press {
  margin: 0 0 ($baseline * 2);

  &__section {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    @media (min-width: $tablet) {
      justify-content: flex-start; }

    &-title {
      flex: 0 0 100%; } }

  &__thumbnail {
    flex-basis: calc(50% - #{$baseline / 2});
    margin: 0 0 $baseline;
    position: relative;

    &:nth-of-type(2n) {
      margin-right: 0; }

    @media (min-width: $tablet) {
      flex-basis: calc(33.33333% - ((2/3) * #{$baseline}));
      margin: 0 $baseline $baseline 0;

      &:nth-of-type(1n) {
        margin-right: $baseline; }

      &:nth-of-type(3n) {
        margin-right: 0; } }

    @media (min-width: $largeDesktop) {
      flex-basis: calc(25% - ((3/4) * #{$baseline}));

      &:nth-of-type(1n) {
        margin-right: $baseline; }

      &:nth-of-type(4n) {
        margin-right: 0; } }

    &::after {
      @include cover;
      background: rgba($black, .9) url(/img/icons/download-white.svg) center / #{$baseline * 2} no-repeat;
      content: '';
      mix-blend-mode: hard-light;
      opacity: 0;
      transition: opacity .5s ease; }

    &:hover {
      &::after {
        opacity: 1; } } }

  &__image {
    width: 100%; } }
