.header {
  left: 0;
  padding: ($baseline * 2) 0;
  position: absolute;
  top: 0;
  transition: transform .5s ease;
  width: 100vw;
  z-index: 99;

  @media (max-width: #{$smallDesktop - 1}) {
    .sidebar-open &,
    &--hide {
      transform: translateY(-100%); } }

  &__container {
    align-items: center;
    justify-content: space-between;
    max-width: none; }

  &__logo {
    height: ($baseline * 2);
    width: ($baseline * 2);

    @media (min-width: $smallDesktop) {
      background: $white;
      left: ($baseline * 1.5);
      position: fixed;
      top: ($baseline * 2);
      width: calc(30vw - #{$baseline * 4}); }

    @media (min-width: $mediumDesktop) {
      left: ($baseline * 2); }

    @media (min-width: $largeDesktop) {
      left: ($baseline * 3); } }

  .logo {
    height: ($baseline * 2);
    width: ($baseline * 2);

    path,
    rect {
      fill: $black;
      transition: fill .5s ease;

      .page--home & {
        fill: $white;

        @media (min-width: $smallDesktop) {
          fill: $black; } } } }

  &__waveform {
    flex: 1 1; }

  &--black {
    .logo {
      path,
      rect {
        fill: $black; } } } }

.nav {
  @media (min-width: $smallDesktop) {
    flex: 0 0 70vw;
    flex-basis: calc(70vw - #{$baseline * 2}); }

  @media (min-width: $largeDesktop) {
    flex-basis: calc(70vw - #{$baseline * 3}); }

  &__list {
    align-items: center;
    display: flex;
    justify-content: flex-end;
    list-style: none;
    margin: 0;

    @media (min-width: $smallDesktop) {
      padding-left: ($baseline * 2);
      width: 100%; }

    @media (min-width: $largeDesktop) {
      padding-left: ($baseline * 3); } }

  &__item {
    margin-left: $baseline;

    &--menu {
      margin-right: auto;

      @media (min-width: $smallDesktop) {
        display: none; }

      svg {
        vertical-align: top;
        width: 1.75em;

        path {
          fill: currentColor; } } }

    &--listen {
      display: none;

      @media (min-width: $largeMobile) {
        display: block; } } }

  &__link {
    color: $black;
    font-size: .875em;
    text-decoration: none;
    transition: color .5s ease;

    &:hover {
      color: $orange; }

    .page--home & {
      color: $white; } } }
