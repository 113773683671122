@import 'reset';

$fontSize: 100%;
$baseline: 1.5em;

/* Browser widths */
$smallMobile: 320px;
$mediumMobile: 400px;
$largeMobile: 480px;
$smallTablet: 600px;
$tablet: 720px;
$smallDesktop: 1020px;
$mediumDesktop: 1120px;
$largeDesktop: 1600px;

$gutterMobile: 12px;
$gutterTablet: 20px;
$gutterDesktop: 55px;

$blockSpacerMobile: $gutterMobile;
$blockSpacerTablet: $gutterTablet;
$blockSpacerDesktop: $gutterDesktop;

$transitionDuration: 0.3s;
$transitionDurationLong: 0.5s;
$transitionTimingFunction: ease;

$primaryFontStack: -apple-system, BlinkMacSystemFont, 'Segoe UI', Helvetica, Arial, sans-serif;
$primaryFont: 'Inter', $primaryFontStack;

/* Colors */
$black: #211b1d;
$gray: #f1f5f7;
$grayTwo: #444;
$grayThree: lighten($black, 5%);
$orange: #ef626c;
$white: #fff;
$red: #b80c09;
/* End Colors */

@import 'mixins';
@import 'animations';
