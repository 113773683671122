.social,
.listen {
  align-items: center;
  display: flex;
  justify-content: center;
  list-style: none;
  pointer-events: none;

  @media (min-width: $smallDesktop) {
    &.main-nav__social {
      justify-content: flex-start;
      margin-left: -.75em; } }

  &:hover {
    .social,
    .listen {
      &__item {
        a {
          opacity: .2;

          &:hover {
            opacity: 1; } } } } }

  &__item {
    flex: 0 0 auto;

    a {
      border: ($baseline / 2) solid transparent;
      box-sizing: content-box;
      display: inline-block;
      height: ($baseline * 1.5);
      pointer-events: all;
      transition: opacity .5s ease;
      width: ($baseline * 1.5);

      @media (min-width: $smallDesktop) {
        height: ($baseline * .75);
        width: ($baseline * .75); } }

    &--soundcloud {
      a {
        background: url(/img/icons/soundcloud-white.svg) center / contain no-repeat;

        .main-nav__social & {
          background-image: url(/img/icons/soundcloud-black.svg); } } }

    &--bandcamp {
      a {
        background: url(/img/icons/bandcamp-white.svg) center / contain no-repeat;

        .main-nav__social & {
          background-image: url(/img/icons/bandcamp-black.svg); } } }

    &--spotify {
      a {
        background: url(/img/icons/spotify-white.svg) center / contain no-repeat;

        .main-nav__social & {
          background-image: url(/img/icons/spotify-black.svg); } } }

    &--apple-music {
      a {
        background: url(/img/icons/apple-white.svg) center / contain no-repeat;

        .main-nav__social & {
          background-image: url(/img/icons/apple-black.svg); } } }

    &--instagram {
      a {
        background: url(/img/icons/instagram-white.svg) center / contain no-repeat;

        .main-nav__social & {
          background-image: url(/img/icons/instagram-black.svg); } } }

    &--twitter {
      a {
        background: url(/img/icons/twitter-white.svg) center / contain no-repeat;

        .main-nav__social & {
          background-image: url(/img/icons/twitter-black.svg); } } }

    &--amazon {
      a {
        background: url(/img/icons/amazon-white.svg) center / contain no-repeat;

        .main-nav__social & {
          background-image: url(/img/icons/amazon-black.svg); } } } } }
