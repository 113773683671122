#skip-to,
#back-to-top {
  position: fixed;
  left: 0;
  opacity: 0;

  &:focus {
    opacity: 1; } }

#skip-to {
  top: 0; }

#back-to-top {
  bottom: 0; }
