.error-page {
  padding: 12.5vmax 0 ($baseline * 3);
  text-align: center;

  &__title {
    color: $orange;
    font-size: 12.5vmax;
    letter-spacing: .25em;
    margin: 0 0 3rem;
    padding: 3rem 0 3rem .25em;
    position: relative;
    z-index: 3;

    &::before {
      @include cover;
      background: url(/img/icons/logo.svg) center / contain no-repeat;
      content: '';
      opacity: .1;
      z-index: -1; } } }

