html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p,
blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img,
ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center,
dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody,
tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure,
figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary,
time, mark, audio, video, main {
  border: 0;
  box-sizing: border-box;
  font-size: 100%;
  font: inherit;
  font-weight: inherit;
  margin: 0;
  padding: 0;
  vertical-align: baseline; }

em {
  font-style: italic; }

strong {
  font-weight: 600; }

/**
 * Address inconsistent and variable font size in all browsers.
 */

small {
  font-size:   60%;
  line-height: 150%; }

/**
 * Address style set to `bolder` in Firefox 4+, Safari, and Chrome.
 */

b,
strong {
  font-weight: bold; }

/**
 * Prevent `sub` and `sup` affecting `line-height` in all browsers.
 */

sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline; }


sup {
  top: -0.5em; }

sub {
  bottom: -0.25em; }

/* Links
   @mixin ========================================================================= */

/**
 * Remove the gray background color from active links in IE 10.
 */

a {
  background-color: transparent; }

/**
 * Improve readability of focused elements when they are also in an
 * active/hover state.
 */

a:active,
a:hover {
  outline: 0; }

img {
  max-width: 100%; }

iframe {
  max-width: 100%; }

/**
  * Remove margin from buttons due to Safari adding them and chrome not.
  */

button {
  margin: 0; }

input, textarea, select {
  box-sizing: border-box; }
