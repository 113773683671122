@mixin primaryFont {
  font-family: $primaryFontStack;

  .font-inter-loaded & {
    font-family: $primaryFont; } }

body {
  @include primaryFont;
  @include clamp('font-size', 16px, 21px, 375px, 1440px);
  color: $black;
  font-weight: 400;
  line-height: $baseline;
  -webkit-font-smoothing: antialias;
  -moz-osx-font-smooth: grayscale;
  font-smooth: always; }

%h0, %h1, %h2, %h3, %h4, %h5 {
  display: block;
  font-weight: 900;
  letter-spacing: -.025em; }

%h4, %h5, %h6 {
  font-weight: 700; }

%h0 {
  font-size: 2.25em; }

%h1 {
  font-size: 2.25em;
  line-height: #{(1.35 / 1.5) * $baseline};
  margin-bottom: #{(.6666666666666667 / 1.5) * $baseline}; }

h1 {
  @extend %h1; }

%h2 {
  font-size: 1.875em;
  line-height: #{(1 / 1.875) * $baseline};
  margin-bottom: #{(1 / 1.875) * $baseline}; }

h2 {
  @extend %h2; }

%h3 {
  font-size: 1.5em;
  line-height: #{(1 / 1.5) * $baseline};
  margin-bottom: #{(1 / 1.5) * $baseline}; }

h3 {
  @extend %h3; }

%h4 {
  font-size: 1.3125em;
  line-height: #{(1.142857142857143 / 1.5) * $baseline};
  margin-bottom: #{(1.142857142857143 / 1.5) * $baseline}; }

h4 {
  @extend %h4; }

%h5 {
  font-size: 1.125em;
  line-height: #{(1.35 / 1.5) * $baseline};
  margin-bottom: #{(1.333333333333333 / 1.5) * $baseline}; }

h5 {
  @extend %h5; }

%h6 {
  font-size: 1em;
  line-height: $baseline;
  margin-bottom: $baseline; }

h6 {
  @extend %h6; }

p {
  display: block;
  margin: 0 0 $baseline;
  width: 100%; }

.screenreader-text {
  left: 0;
  position: absolute;
  top: -9999vh; }

a {
  color: inherit;
  transition: opacity .5s ease; }

ul, ol {
  margin: 0 0 $baseline; }

ol {
  margin-left: $baseline; }

ul {
  list-style: disc inside; }

code {
  font-family: monospace; }

small {
  font-size: .875em; }

button {
  line-height: inherit; }
